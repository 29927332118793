
const Resume = [
    {
        id: '1',
        title:'Former Designer & Senior Developer',
        date:'2020 - Present',
        location:'ABC Design Agency, London',
    },
    {
        id: '2',
        title:'Lead Designer & CEO',
        date:'2018 - 2020',
        location:'Magnificence TOP, London',
    },
    {
        id: '3',
        title:'Junior Designer & Intern',
        date:'2016 - 2018',
        location:'Fastoy Agency, London',
    },
    {
        id: '4',
        title:'Bachelor Degree of Visual Design',
        date:'2012 - 2016',
        location:'Cambridge University',
    },
    {
        id: '5',
        title:'Former Designer & Developer',
        date:'2020 - Present',
        location:'ABC Design Agency, London',
    },
    {
        id: '6',
        title:'Lead Designer & CEO',
        date:'2018 - 2020',
        location:'Magnificence TOP, London',
    },
    {
        id: '7',
        title:'Junior Designer & Intern',
        date:'2016 - 2018',
        location:'Fastoy Agency, London',
    },
    {
        id: '8',
        title:'BSC Degree of Visual Design',
        date:'2012 - 2016',
        location:'Cambridge University',
    },




]
export default Resume;
/* images */
import PImg1 from "../images/portfolio/1.jpg"
import PImg2 from "../images/portfolio/4.jpg"
import PImg3 from "../images/portfolio/3.jpg"
import PImg4 from "../images/portfolio/2.jpg"
import PImg5 from "../images/portfolio/img-1.jpg"
import PImg6 from "../images/portfolio/img-2.jpg"
import PImg7 from "../images/portfolio/img-3.jpg"
import PImg8 from "../images/portfolio/img-4.jpg"
import PImg9 from "../images/portfolio/lw-1.jpg"
import PImg10 from "../images/portfolio/lw-2.jpg"
import PImg11 from "../images/portfolio/lw-3.jpg"
import PImg12 from "../images/portfolio/lw-4.jpg"
import PImg13 from "../images/portfolio/cn-1.jpg"
import PImg14 from "../images/portfolio/cn-2.jpg"
import PImg15 from "../images/portfolio/cn-3.jpg"
import PImg16 from "../images/portfolio/cn-4.jpg"

const PortfolioItems = [
    {
        id: 1,
        category: "app brand",
        image: PImg1,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design"
    },
    {
        id: 2,
        category: "website",
        image: PImg2,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-2"
    },
    {
        id: 3,
        category: "app webapp brand",
        image: PImg3,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-3"
    },
    {
        id: 4,
        category: "brand webapp",
        image: PImg4,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-4"
    },
    {
        id: 5,
        category: "website",
        image: PImg5,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-5"
    },
    {
        id: 6,
        category: "brand",
        image: PImg6,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-7"
    },
    {
        id: 7,
        category: "website ",
        image: PImg7,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-7"
    },
    {
        id: 8,
        category: "brand",
        image: PImg8,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-8"
    },
    {
        id: 9,
        category: "brand",
        image: PImg9,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-9"
    },
    {
        id: 10,
        category: "brand",
        image: PImg10,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-10"
    },
    {
        id: 11,
        category: "brand",
        image: PImg11,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-11"
    },
    {
        id: 12,
        category: "brand",
        image: PImg12,
        title: "Geometric Shape Design",
        subtitle: "Brand Design",
        slug: "Geometric-Shape-Design-13"
    },
    {
        id: 13,
        category: "brand",
        image: PImg13,
        title: "Business Consulting",
        subtitle: "Business, Finance",
        slug: "Geometric-Shape-Design-14"
    },
    {
        id: 14,
        category: "website",
        image: PImg14,
        title: "Business Consulting",
        subtitle: "Business, Finance",
        slug: "Geometric-Shape-Design-15"
    },
    {
        id: 15,
        category: "brand",
        image: PImg15,
        title: "Business Consulting",
        subtitle: "Business, Finance",
        slug: "Geometric-Shape-Design-16"
    },
    {
        id: 16,
        category: "website",
        image: PImg16,
        title: "Business Consulting",
        subtitle: "Business, Finance",
        slug: "Geometric-Shape-Design-17"
    },
];
export default PortfolioItems;